.chatbot-modal {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .chatbot-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    max-width: 90%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
  }
  
  .chatbot-container .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .chatbot-title {
    margin-top: 0;
  }
  
  .chat-window {
    border: 1px solid #ccc;
    padding: 10px;
    height: 200px;
    overflow-y: auto;
    margin-bottom: 10px;
  }
  
  .chat-message {
    margin: 5px 0;
  }
  
  .chatbot {
    color: blue;
  }
  
  .chat-input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .chat-toggle-button {
    background: #2638EA;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .chat-toggle-button:hover {
    background: #0056b3;
  }
  