.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.login-logo {
  max-height: 100px;
  margin-bottom: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  width: 500px; /* Adjusted width for better layout */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.modal-title {
  font-size: 2em;
  margin-bottom: 20px;
}

.modal-content {
  text-align: center;
}

.modal-button {
  background: #2638EA;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.modal-button:hover {
  background: #0056b3;
}

.modal-section {
  margin-bottom: 20px;
}

.modal-section h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.modal-section label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.modal-textarea {
  width: 100%;
  height: 80px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  resize: vertical;
}

.modal-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.execute-assessment-button {
  background: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.execute-assessment-button:hover {
  background: #218838;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.login-form {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: left;
}

.login-logo {
  max-height: 100px;
  display: block;
  margin: 0 auto 20px auto;
}

.login-form label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
}

.login-form input[type="text"],
.login-form input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.remember-me {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: #333;
}

.remember-me input {
  margin-right: 10px;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #2638EA;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.login-button:hover {
  background-color: #0056b3;
}

.error-message {
  margin-top: 20px;
  color: red;
  font-weight: bold;
  text-align: center;
}

.logo-container {
  background-color: #fff; /* Light background for the logo if needed */
  padding: 10px;
  display: inline-block;
  margin-bottom: 20px;
}

.sidebar {
  position: sticky;
  top: 0;
  height: 100vh;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner > div {
  width: 8px;
  height: 8px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out both;
  animation: bouncedelay 1.4s infinite ease-in-out both;
  margin: 0 2px;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes bouncedelay {
  0%, 80%, 100% { 
    transform: scale(0);
    -webkit-transform: scale(0);
  } 40% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.iframe-container {
  position: fixed;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 20px;
  box-sizing: border-box;
}

.close-button {
  position: absolute;
  top: 3px;
  right: 5px;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  /* background-color: #333; */
  cursor: pointer;
}

.fullsize-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.dashboard {
  padding: 20px;
}

.dashboard-title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
}

.use-case-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.use-case-card {
  background-color: #ffffff;
  padding: 20px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 300px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.use-case-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.use-case-card h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.use-case-card p {
  font-size: 1.2em;
  color: #555;
}

.use-case-compliance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
}

.compliance-indicator {
  font-size: 1.5em;
  color: green;
}

.compliance-indicator.non-compliant {
  color: red;
}

.add-use-case {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 200px;
  border: 1px dashed #ccc;
  border-radius: 10px;
  cursor: pointer;
}

.add-use-case-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 2em;
  color: #555;
}

.add-use-case-button:hover {
  color: #000;
}

.use-case-risk-classification {
  font-size: 1.2em;
  color: #888;
  margin-top: 5px;
}

/* Chatbot Overlay Styles */
.chatbot-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatbot-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

.chatbot-container .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.chatbot-title {
  margin-top: 0;
}

.chat-window {
  border: 1px solid #ccc;
  padding: 10px;
  height: 200px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.chat-message {
  margin: 5px 0;
}

.chatbot {
  color: blue;
}

.chat-input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
